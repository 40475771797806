<template>
  <div class="add-service">
    <h1 class="admin-title">{{$t('Edit Service')}}</h1>
    <ValidationObserver ref="validator">
      <form @submit.prevent>
        <b-row>
          <b-col cols="12" sm="6" md="4">
            <RFValidation name="Name" rules="required">
              <RFInput label="Name" v-model="service.name" />
            </RFValidation>
          </b-col>
          <b-col cols="12" sm="6" md="4">
            <RFValidation name="Description" rules="required">
              <RFInput label="Description" v-model="service.description" />
            </RFValidation>
          </b-col>
          <b-col cols="12" sm="6" md="4">
            <RFValidation name="Price" rules="required">
              <RFCurrencyInput label="Price" v-model="service.price" />
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="6" md="4">
            <RFValidation name="Type" rules="required">
              <RFSelect top-label="Type" v-model="selectedServiceType" @input="service.typeId = $event" :list="serviceTypeList"/>
            </RFValidation>
          </b-col>
          <b-col cols="12" sm="6" md="4">
            <RFCheckbox class="mt-4" label="Recurring" v-model="service.isRecurrent" />
          </b-col>
          <b-col cols="12" sm="6" md="4">
            <RFCheckbox class="mt-4" label="Enabled" v-model="service.isEnabled" />
          </b-col>
        </b-row>
        <b-row class="justify-content-end mt-5">
          <b-col cols="auto">
            <RFButton class="mt-3" label="Save" background="#5CB85C" v-if="!loading" @on-click="save"/>
            <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>

    <b-row class="service-section">
      <b-col cols="12" class="mb-4">
        <div class="header d-flex justify-content-between">
          <div class="section-title">{{$t('Service Icon')}}</div>
          <div class="add-images-label cursor-pointer" @click="openIconModal" v-if="!icon">
            <FontAwesomeIcon :icon="['fas', 'plus-circle']" color="#5CB86C" /> {{$t('Add Icon')}}
          </div>
        </div>
      </b-col>
      <b-col>
        <AdminImageList :images="icon ? [ ...icon ] : []" :limit="1" @delete="deleteIcon" :is-accommodation="false" :editable="false" min-height="250px"/>
      </b-col>
    </b-row>
    <b-row class="service-section">
      <b-col cols="12" class="mb-4">
        <div class="header d-flex justify-content-between">
          <div class="section-title">{{$t('Service Images')}}</div>
          <div class="add-images-label cursor-pointer" @click="openImagesModal">
            <FontAwesomeIcon :icon="['fas', 'plus-circle']" color="#5CB86C" /> {{$t('Add Images')}}
          </div>
        </div>
      </b-col>
      <b-col>
        <AdminImageList :images="images" @delete="deleteImage" :editable="false" min-height="300px"/>
      </b-col>
    </b-row>

    <AddImagesModal @update="updateImages"/>
    <AddImagesModal id="icon" @update="updateIcon" :limit="1" :is-accommodation="false"/>
  </div>
</template>

<script>
import RFValidation from '@/components/forms/RFValidation'
import RFCurrencyInput from '@/components/forms/RFCurrencyInput'
import RFInput from '@/components/forms/RFInput'
import RFRadio from '@/components/forms/RFRadio'
import RFSelect from '@/components/forms/RFSelect'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import RFCheckbox from '@/components/forms/RFCheckbox'
import RFFile from '@/components/forms/RFFile'

import AdminImageList from '@/components/admin/AdminImageList'
import AddImagesModal from '@/components/admin/AddImagesModal'

import { adminService, additionalService } from '@/services/admin'
import { getAsset } from '@/helpers'

import { mapState } from 'vuex'

export default {
  name: 'AdminEditService',
  components: {
    RFValidation,
    RFCurrencyInput,
    RFInput,
    RFRadio,
    RFSelect,
    RFCheckbox,
    RFFile,
    RFButton,
    RFLoader,

    AdminImageList,
    AddImagesModal,
  },
  data() {
    return {
      service: null,
      icon: null,
      images: [],
      loading: false,
      serviceTypeList: [],
      selectedServiceType: null,
      newImages: []
    }
  },
  computed: {
    ...mapState({
      storedService: state => state.adminServiceStore.service,
      serviceTypes: state => state.adminServiceTypeStore.serviceTypes,
    })
  },
  beforeMount() {
    this.service = { ...this.storedService }
    this.service.typeId = this.service.type.id
    this.icon = this.service.icon
    this.images = this.service.images
    this.serviceTypeList = this.serviceTypes.map(serviceType => ({ label: serviceType.name, value: serviceType.id }))
    this.selectedServiceType = this.serviceTypes.find(serviceType => serviceType.id === this.service.type.id).name
  },
  methods: {
    openIconModal() {
      this.$root.$emit('bv::show::modal', 'add-images-modal-icon')
    },
    openImagesModal() {
      this.$root.$emit('bv::show::modal', 'add-images-modal')
    },
    async updateIcon(images) {
      const formData = new FormData()

      formData.append('file', images[0])

      const { data: newIcon } = await additionalService.addIcon(this.service.id, formData)

      this.icon = newIcon
      this.$toasted.success(this.$i18n.t(`Icon successfully added!`))
    },
    async deleteIcon(id) {
      await adminService.deleteImage(id)
      this.icon = null
    },
    async updateImages(images) {
      const formData = new FormData()

      images.forEach(image => formData.append('files', image))

      const { data: newImages } = await additionalService.addImages(this.service.id, formData)

      this.images.push(...newImages)
      this.$toasted.success(this.$i18n.t(`Images successfully added!`))
    },
    async deleteImage(id) {
      await adminService.deleteImage(id)

      const index = this.service.images.findIndex(image => image.id === id)
      this.service.images.splice(index, 1)
    },
    async save() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true

        delete this.service.images
        delete this.service.icon

        const { data: service, status } = await additionalService.updateService(this.service)

        if (status === 200) {
          this.service = { ...service }
          this.$toasted.success(this.$i18n.t(`Service successfully added!`))
          this.$router.push({ name: 'service-list' })
        } else {
          this.$toasted.error(this.$i18n.t(`Error during add service`))
        }

        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-service {
  .icon {
    max-width: 100%;
  }
  .service-section {
    margin-top: 40px;

    .header {
      border-bottom: 1px solid $dark-gray;
    }
    .section-title {
      @include font-style($montserrat, 'semibold', $font-22);
      color: $dark-blue;
      text-transform: uppercase;
      margin: 0;
    }
    .add-images-label {
      @include font-style($montserrat, 'semibold', $font-16);
      color: $success;
    }
  }
}
</style>